/* Updated page styling */
.contract-interaction-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #e9ecef;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Center the card in a large, neat box */
  .contract-card-container {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    padding: 30px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  
  /* Card styling for contract interaction */
  .contract-card {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
  }
  
  /* Profile section styling */
  .profile-section p {
    margin: 8px 0;
    font-size: 18px;
  }
  
  /* Input fields styling */
  .input-field {
    width: 100%;
    padding: 12px;
    margin: 12px 0;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 16px;
  }
  
  /* Button styling */
  .update-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 12px;
    transition: background-color 0.3s ease;
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }
  
  .processing-icon {
    font-size: 24px;
    color: #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .contract-card-container {
      padding: 20px;
      max-width: 90%;
    }
  
    .profile-section p {
      font-size: 16px;
    }
  
    .update-button {
      font-size: 16px;
    }
  }
  